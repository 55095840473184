// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Axios from 'axios'
import * as moment from 'moment'
import storage from '@/components/storage.js';
import Pivot from "vue-flexmonster";
import qs from 'qs'
import 'flexmonster/flexmonster.css';
import './App.css';

import { Notification } from 'element-ui'

// import "vue-easytable/libs/theme-default/index.css"; // import style
// import VueEasytable from "vue-easytable"; // import library

// Vue.use(VueEasytable);

import WotDesign from 'wot-design'
import 'wot-design/lib/theme-default/index.css'

Vue.use(WotDesign);
Vue.use(Pivot);

// import { SelectPicker } from 'wot-design'

// Vue.use(SelectPicker)

require('./registerServiceWorker')



Vue.config.productionTip = false

Vue.use(ElementUI);
Vue.prototype.axios= Axios
Vue.prototype.moment= moment;
Vue.prototype.storage= storage;
Vue.prototype.$dt = process.env.NODE_ENV=='production'?'https://demo.wsoa.vip':'/data';
Vue.prototype.$pivot = process.env.NODE_ENV=='production'?'':'/pivot';

Vue.prototype.$isMobile =()=> {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag;
}

Vue.prototype.$baseNotify = (
  message,
  title,
  type = 'success',
  position = 'top-right',
  duration = 3000
) => {
  Notification({
    title,
    message,
    type,
    duration,
    position,
  })
}



new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
