<template>
  <div class="container">
    <div class="select-box">
      <div class="select-top">
        <h3>库存查询</h3>
      </div>
      <div class="show-content" v-show="showRequestFlag">

        <el-row :gutter="20">
  <el-col :span="2" :xs="2"><div class="grid-content bg-purple">
    <span class="select-desc">厂家:</span>
           

  </div></el-col>
  <el-col :span="6" :xs="10"><div class="grid-content bg-purple">

    <el-select
              v-model="GYSdtValue"
              size="mini"
              multiple
              filterable
              default-first-option
              placeholder="请选择厂家"
            >
              <el-option
                v-for="item in GYSdtList"
                :key="item.gysbh"
                :label="item.dwjc"
                :value="item.gysbh"
              >
              </el-option>
            </el-select>

  </div></el-col>
  <el-col :span="2" :xs="2"><div class="grid-content bg-purple"> <span class="select-desc">仓库:</span></div></el-col>
  <el-col :span="6" :xs="10"><div class="grid-content bg-purple"><el-select
              v-model="cangkuValue"
               size="mini"
              multiple
              filterable
              default-first-option
              placeholder="请选择、空只查大仓库"
            >
              <el-option
                v-for="item in CangKudt"
                :key="item.ckbh"
                :label="item.ckmc"
                :value="item.ckbh"
              >
              </el-option>
            </el-select>
           </div></el-col>
            
  <el-col :span="4" :xs="12"><div class="grid-content bg-purple">  <el-checkbox
              v-model="ChanPinIsT"
              size="mini"
              label="显示批号"
              border
            ></el-checkbox></div></el-col>
            <el-col :span="4"  :xs="12"><div class="grid-content bg-purple">  <el-button type="primary" size="mini" @click="toQuery"
            >查询</el-button>

          <el-button type="info" size="mini" @click="exportNewList"
            >导出</el-button></div></el-col>
</el-row>

       
      </div>

       
    </div>

    <div class="table-box">
      <div class="table-left">
        <el-table
          :data="ZongBiao"
          border
          show-summary
          @row-click="rowClick"
          v-loading="tableLTloading"

          size="mini"
        >
          <el-table-column type="index"> </el-table-column>
           <el-table-column prop="cpmc" sortable label="产品名称">
           <template slot-scope="scope">
                <p class="content-detail">{{scope.row.cpmc}}</p>
                <div class="content-tag">
                  <el-tag type="success" size="mini" v-for="bq in GetBiaoQian(scope.row.BiaoQian)"
                  :key="bq"  >{{bq}}</el-tag>
                </div>
              </template>
          </el-table-column>

          <el-table-column prop="ggxh" sortable label="规格" width="80">
          </el-table-column>

          <el-table-column prop="ph" sortable label="批号" width="80">
          </el-table-column>
          <el-table-column prop="sjkyl" sortable label="可用量" width="80">
          </el-table-column>
          <el-table-column prop="sjkyxs" sortable label="箱数" width="80">
          </el-table-column>
          <el-table-column prop="cbje" sortable label="金额" width="80">
          </el-table-column>
          <el-table-column prop="sjkc" sortable label="现存量" width="80">
          </el-table-column>
          <el-table-column prop="sjkcxs" sortable label="现存箱数" width="80">
          </el-table-column>
          <el-table-column prop="jhckl" sortable label="计划出库" width="80">
          </el-table-column>
          <el-table-column prop="cpbh" sortable label="产品编号" width="80">
          </el-table-column>
          <el-table-column prop="ztsl" sortable label="采购在途" width="80">
          </el-table-column>
          <el-table-column prop="txm" sortable label="条码" width="130">
          </el-table-column>
          <el-table-column prop="lbmc" sortable label="类别" width="130">
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div class="table-box table-user">
      <div class="block" v-for="ck in ckdtList" :key="ck.id">
        <el-avatar size="small" :src="ck.headIcon" class="ava"></el-avatar>
        <div class="name">{{ ck.username }}</div>
        <span class="time">{{ ck.clicksj }}</span>
      </div>
    </div>

  </div>
</template>

<script>
import * as moment from "moment";
import XLSX from "xlsx";
export default {
  data() {
    return {
      dialogFormVisible: false,
      ChanPinIsT: false,
      isZengPin: false,
      tableLTloading: false,
      cangkuValue:'',

      showRequestFlag: true,
      productList: [],
      customerList: [],

      value: [],
      MenDianIsT: false,
      value1: "",
      value2: "",
      CangKudt: [],
      FLdtList: [],
      GYSdtList: [],
      PPdtList: [],
      ckdtList: [],
      CPdtValue: "",
      FLdtValue: "",
      GYSdtValue: "",
      PPdtValue: "",
      MCdtList: [],
      DQdtList: [],
      YWdtList: [],
      QYdtList: [],
      MCdtValue: "",
      DQdtValue: "",
      YWdtValue: "",
      QYdtValue: "",
      MenDianT: "",
      ChanPinT: "",
      ZongBiao: [],

      listItems: [
        { label: "名称" },
        { label: "规格" },
        { label: "批号" },
        { label: "可用量" },
        { label: "箱数" },
        { label: "金额" },
        { label: "现存量" },
        { label: "现存箱数" },
        { label: "计划出库" },
        { label: "产品编号" },
        { label: "采购在途" },
        { label: "条码" },
        { label: "类别" },
      ],
      token: "",
      where: {},
    };
  },
  mounted() {
    this.token = this.storage.get("userToken");
    if (!this.token) {
      this.$router.push("/pcLogin");
    }


    this.getChanPin();
    this.getMenDian();
  },
  methods: {
     GetBiaoQian(bpT){
      if(bpT){
        var bps=[];
        bps = bpT.split("|");
        return bps;
      }else{
        return [];
      }
    },
    //点击表格行触发事件
    //row 包含该行中所有的数据
    rowClick(row, column, event) {
      // switch (column.property) {
      //   case "PuShiShu":
      //     this.where.cpbh = row.cpbh;
      //     this.MenDianFenJietabs = "XiaoShouMenDian";
      //     this.where.method = "PuShiMenDian";
      //     this.axios
      //       .get(`/App/NBwtsp/ShuJuZhongXin.ashx`, {
      //         params: this.where,
      //       })
      //       .then((res) => {
      //         if (res.data.Success) {
      //           this.MenDianFenJieData = JSON.parse(res.data.Data);
      //           this.dialogFormVisible = true;
      //         } else {
      //           alert(res.data.Message);
      //         }
      //       });
      //     console.log(row.cpbh);

      //     break;
      // }
      //console.log(row,column,event);
    },
    //弹出窗的导出
    exportDialog() {},
    exportNewList() {
      //基础设置
      var xlsxParam = { raw: true }; //转换成excel时，使用原始的格式
      var tabHandle = []; //导出表头
      // this.listItems 是表头数据
      this.listItems.forEach((item) => {
        tabHandle.push(item.label);
      }); //表头数组
      let tableData2 = [];
      tableData2.push(tabHandle);

      this.ZongBiao.forEach((item, index) => {
        let rowData = [];
        //导出内容的字段
        rowData = [
          item.cpmc,
          item.ggxh,
          item.ph,
          item.sjkyl,
          item.sjkyxs,
          item.cbje,
          item.sjkc,item.sjkcxs,
          item.jhckl,
          item.cpbh,
          item.ztsl,
          item.txm,item.lbmc
        ];
        tableData2.push(rowData);
      });

      let workSheet2 = XLSX.utils.json_to_sheet(tableData2, xlsxParam);

      let bookNew = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(bookNew, workSheet2, "库存");

      let name = "库存查询.xlsx"; //表格名称
      XLSX.writeFile(bookNew, name); // 保存的文件名
    },
    //时间间隔更改
    changeDayNum(e) {
      // console.log(111)
      //console.log(e)
      if (this.jssj) {
        var nowDate = moment(this.jssj);
        var dateFrom = nowDate.subtract(e, "d").format("YYYY-MM-DD");
        this.kssj = dateFrom;
      }
    },
    handleClick(tab, event) {
      //分解切换
      console.log(tab, event);
    },
    //选择开始时间
    changeKS(e) {
      // if (this.dayValue) {
      //   var nowDate = moment(e);
      //   var dateFrom = nowDate.add(this.dayValue, "d").format("YYYY-MM-DD");
      //   console.log(dateFrom);
      //   this.jssj = dateFrom;
      // }
      // this.kssj = moment(e).format("YYYY-MM-DD");
    },
    //选择结束时间
    changeJS(e) {
      // if (this.dayValue) {
      //   var nowDate = moment(e);
      //   var dateFrom = nowDate
      //     .subtract(this.dayValue, "d")
      //     .format("YYYY-MM-DD");
      //   this.kssj = dateFrom;
      // }
      // this.jssj = moment(e).format("YYYY-MM-DD");
    },
    getChanPin() {
      this.axios
        .get(
          `${this.$dt}/App/NBwtsp/ShuJuZhongXin.ashx?method=XiaZaiKuCunGongYingShang&token=` +
            this.token
        )
        .then((res) => {
          if (res.status == 200) {

            this.GYSdtList = res.data.GYSdt;
            this.ckdtList = res.data.ckdt;
            this.CangKudt = res.data.CangKudt;
          }
        });
    },
    getMenDian() {
      /* this.axios
        .get(
          "/App/NBwtsp/ShuJuZhongXin.ashx?method=XiaZaiSuoYouMenDian&token=" +
            this.token
        )
        .then((res) => {
          if (res.status == 200) {
            this.MCdtList = res.data.MCdt;
            this.DQdtList = res.data.DQdt;
            this.YWdtList = res.data.YWdt;
            this.QYdtList = res.data.QYdt;
          }
        }); */
    },
    // 错误消息提示
    openError(errMessage) {
      this.$message({
        showClose: true,
        message: errMessage,
        type: "warning",
      });
    },
    //查询
    toQuery() {
      // this.showRequestFlag = false;
      let CangKu = "";
      for (var i = 0; i < this.cangkuValue.length; i++) {
        CangKu = CangKu + "'" + this.cangkuValue[i] + "'";
        if (i + 1 != this.cangkuValue.length) {
          CangKu = CangKu + ",";
        }
      }

      this.ZongBiao = [];
      this.tableLTloading = true;

      this.where = {
        method: "KuCunChaXun",
        IsPiHao: this.ChanPinIsT ? "1" : "0",
         CangKu:CangKu,
        ChangJia: this.GYSdtValue.join(","),
        token: this.token,
      };

      this.axios
        .get(`${this.$dt}/App/NBwtsp/ShuJuZhongXin.ashx`, {
          params: this.where,
        })
        .then((res) => {
          // console.log(res);

          this.tableLTloading = false;
          if (res.data.Success) {

            this.ZongBiao = JSON.parse(res.data.Data);

          } else {
            alert(res.data.Message);
          }
        });
    },

  },
};
</script>

<style scoped lang="scss">
.grid-content{
  padding-bottom: 10px;
  text-align: center;
}
.container {
  // width: 100%;
  // background: #fff;
  // padding: 40px;
  // background-color: #409eff; /* 浏览器不支持时显示 */
  // background-image: linear-gradient(#409eff,#409eff,#fff);
  // min-width: 1200px;

  background: #f9fafc;
  .select-box {
    display: flex;
    flex-direction: column;
    // background:#eee;
    .select-top,
    .select-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 5px;
      .select-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        // width: 200px;
      }
    }
    .select-top {
      // background-color: #409eff;

      color: #333;
      justify-content: space-around;

      .select-date-box,
      .select-indicators-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 495px;
      }
      .select-indicators-box {
        justify-content: flex-end;
      }
    }
    .select-content {
      margin: 5px 0;
      .select-item {
        width: 18%;
      }
      .mendian-item {
        width: 40%;
      }
    }
    .select-content2 {
      .select-item {
        width: 20%;
        &:last-child {
          width: 40%;
        }
        .check-box {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }
      .mendian-item{
        width: 40%;
      }
    }
  }
  .table-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: calc(100% - 80px);
    padding: 0 40px;
    // margin: 0px 0;
    .table-left,
    .table-right {
      width: 100%;
      // max-width: 862px;
      // margin: 40px;
    }
    // .table-left {
    //   margin-left: 30px;
    // }
    // .table-right {
    //   margin-right: 30px;
    // }
  }
}
.select-desc {
  display: block;
  width: 80px;
  text-align: right;
  flex-shrink: 0;
}
.indicators-content {
  background-color: #fff;
  width: 110px;
  height: 30px;
  line-height: 30px;
  border-radius: 6px;
  padding-left: 6px;
}

.day-num-box /deep/ .el-input {
  width: 90px;
}
.day-num-box {
  margin-left: 20px;
}
.date-box /deep/ .el-date-editor.el-input {
  width: 100%;
  // min-width: 120px;
}
.date-box /deep/ .el-input--suffix .el-input__inner {
  padding-right: 0;
}
// .check-box {
//   margin-left: 20px;
// }
.check-box /deep/ .el-checkbox.is-bordered {
  margin-right: 0;
}
.select-content /deep/ .el-checkbox {
  margin-left: 5px;
}
.container /deep/ .el-select__tags {
  flex-wrap: nowrap;
  overflow: hidden;
}
.operate-box {
  padding: 10px;
  color: #fff;
  // color: #333;
  // background-image: linear-gradient(
  //   to bottom,
  //   rgba(240, 242, 245, 0.4),
  //   rgba(202, 205, 207, 0.4),
  //   rgba(240, 242, 245, 0.4)
  // );
  background: #66b1ff;
  cursor: pointer;
}
.el-icon-d-arrow-left,
.el-icon-d-arrow-right {
  transform: rotate(90deg);
}
.show-content {
  // background: rgba(255,255,255,.4);
  background: #f9fafc;
}
.btns {
  margin-bottom: 20px;
}
.product-input,
.mendian-input {
  width: 100%;
  height: 38px;
  border: 1px solid #eee;
  border-radius: 6px;
  padding-left: 10px;
  &::placeholder {
    color: #c8c9cc;
  }
}
.table-title {
  width: 100%;
  position: relative;

  border-bottom: 1px solid #eee;
  .table-download {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }
}
.container /deep/ .el-select {
  width: 100%;
}
.product-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 40% !important;
}
.select-content /deep/ .el-button {
  margin-left: 5px;
}
.table-box /deep/ .cell {
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
}
.table-box /deep/ .cell  .content-detail{
      margin-top: 9px;
}
.table-box /deep/ .cell  .content-tag{
      position: absolute;
    top: 0px;
    right: 0px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.table-box /deep/ .cell  .content-tag .el-tag{
    margin-right: 3px;
    font-size: 8px;
}
.table-user {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  .block {
    display: flex;
    flex-direction: column;
    align-items: center;
    .ava,
    .name,
    .time {
      margin-top: 3px;
    }
    .name {
      font-size: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
    .time {
      font-size: 8px;
      padding: 3px;
    }
  }
}
</style>
