<template>
  <div class="container">
    <div class="select-box">
      <div class="select-top">
        <div class="select-date-box">
          <div class="select-item date-box">
            <span class="select-desc">开始时间:</span>
            <el-date-picker
              v-model="kssj"
              align="right"
              size="mini"
              type="date"
              placeholder="选择日期"
              :picker-options="pickerOptions"
              @change="changeKS"
            >
            </el-date-picker>
          </div>
          <div class="select-item date-box">
            <span class="select-desc">结束时间:</span>
            <el-date-picker
              v-model="jssj"
              align="right"
              size="mini"
              type="date"
              placeholder="选择日期"
              :picker-options="pickerOptions"
              @change="changeJS"
            >
            </el-date-picker>
          </div>

        </div>
        <h3>出入库流水账</h3>
        <div class="select-indicators-box">
          <div class="select-item">
            <span class="select-desc">类型:</span>
            <el-select
              v-model="typeValue"
              size="mini"
              multiple
              filterable
              default-first-option
              placeholder="请选择类型"
            >
              <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.value"
                :value="item.id"
              >
              </el-option>
            </el-select>


          </div>
        </div>
      </div>
      <div class="show-content" v-show="showRequestFlag">
        <div class="select-content">
          <div class="select-item">
            <span class="select-desc">总公司:</span>
            <el-select
              v-model="CompanyValue"
              size="mini"
              multiple
              filterable
              default-first-option
              placeholder="请选择总公司"
            >
              <el-option
                v-for="item in companyList"
                :key="item.id"
                :label="item.value"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="select-item">
            <span class="select-desc">大区:</span>
            <el-select
              v-model="DQdtValue"
              size="mini"
              multiple
              filterable
              default-first-option
              placeholder="请选择大区"
            >
              <el-option
                v-for="item in DQdtList"
                :key="item.khbh"
                :label="item.dwjc"
                :value="item.khbh"
              >
              </el-option>
            </el-select>
          </div>
          <div class="select-item">
            <span class="select-desc">业务员:</span>
            <el-select
              v-model="YWdtValue"
              size="mini"
              multiple
              filterable
              default-first-option
              placeholder="请选择业务员"
            >
              <el-option
                v-for="item in YWdtList"
                :key="item.ywybh"
                :label="item.xm"
                :value="item.ywybh"
              >
              </el-option>
            </el-select>
          </div>
          <div class="select-item">
            <span class="select-desc">区域:</span>
            <el-select
              v-model="QYdtValue"
              size="mini"
              multiple
              filterable
              default-first-option
              placeholder="请选择区域"
            >
              <el-option
                v-for="item in QYdtList"
                :key="item.ssqy"
                :label="item.ssqy"
                :value="item.ssqy"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="select-content select-content2">
          <div class="select-item">
            <span class="select-desc">分类:</span>
            <el-select
              v-model="FLdtValue"
              size="mini"
              multiple
              filterable
              default-first-option
              placeholder="请选择分类"
            >
              <el-option
                v-for="item in FLdtList"
                :key="item.lbdm"
                :label="item.lbmc"
                :value="item.lbdm"
              >
              </el-option>
            </el-select>
          </div>
          <div class="select-item">
            <span class="select-desc">厂家:</span>
            <el-select
              v-model="GYSdtValue"
              size="mini"
              multiple
              filterable
              default-first-option
              placeholder="请选择厂家"
            >
              <el-option
                v-for="item in GYSdtList"
                :key="item.gysbh"
                :label="item.dwjc"
                :value="item.gysbh"
              >
              </el-option>
            </el-select>
          </div>
          <div class="select-item">
            <span class="select-desc">品牌:</span>
            <el-select
              v-model="PPdtValue"
              size="mini"
              multiple
              filterable
              default-first-option
              placeholder="请选择品牌"
            >
              <el-option
                v-for="item in PPdtList"
                :key="item.wwwppbh"
                :label="item.wwwppmc"
                :value="item.wwwppbh"
              >
              </el-option>
            </el-select>
            <el-checkbox
              v-model="isZengPin"
              size="mini"
              label="剔除赠品"
              border
            ></el-checkbox>
          </div>
        </div>
        <div class="select-content">
          <div class="select-item mendian-item">
            <span class="select-desc">门店:</span>
            <input
              type="text"
              class="mendian-input"
              v-show="MenDianIsT"
              v-model="MenDianT"
              placeholder="门店关键字"
            />
            <el-select
              v-show="!MenDianIsT"
              v-model="MCdtValue"
              size="mini"
              multiple
              filterable
              default-first-option
              placeholder="请选择门店"
            >
              <el-option
                v-for="item in MCdtList"
                :key="item.khbh"
                :label="item.dwjc"
                :value="item.khbh"
              >
              </el-option>
            </el-select>
            <div class="check-box">
              <el-checkbox
                v-model="MenDianIsT"
                size="mini"
                label="模糊"
                border
              ></el-checkbox>
            </div>
          </div>
          <div class="select-item product-item">
            <span class="select-desc">产品:</span>
            <input
              type="text"
              class="product-input"
              v-show="ChanPinIsT"
              v-model="ChanPinT"
              placeholder="产品关键字"
            />
            <el-select
              v-show="!ChanPinIsT"
              v-model="CPdtValue"
              size="mini"
              filterable
              multiple
              default-first-option
              placeholder="请选择产品"
            >
              <el-option
                v-for="item in CPdtList"
                :key="item.cpbh"
                :label="item.cpmc"
                :value="item.cpbh"
              >
              </el-option>
            </el-select>

            <!-- <div class="check-box"> -->
            <el-checkbox
              v-model="ChanPinIsT"
              size="mini"
              label="模糊"
              border
            ></el-checkbox>

            <!-- </div> -->
          </div>
          <el-button type="primary" size="mini" @click="toQuery"
            >查询数据</el-button
          >

          <el-button type="info" size="mini" @click="exportNewList"
            >导出</el-button>
        </div>
      </div>

      <div
        v-show="!showRequestFlag"
        class="operate-box"
        @click="showRequestFlag = true"
      >
        展开。。。
      </div>
    </div>
    <div class="table-box">

      <div class="table-right">
        <el-table
          :data="ZongBiao"
          border
          show-summary
          @row-click="rowClick"
          v-loading="tableLTloading"

          size="mini"
        > <el-table-column
      type="index" >
    </el-table-column>
          <el-table-column prop="dh" sortable label="单号" width="130">
          </el-table-column>

          <el-table-column  prop="wldw"  sortable label="客户" width="200">
          </el-table-column>

          <el-table-column prop="cpmc" sortable label="产品" width="200">
          </el-table-column>
          <el-table-column prop="sl" sortable label="数量" width="80">
          </el-table-column>
          <el-table-column prop="sldesp" sortable label="箱数" width="80">
          </el-table-column>
          <el-table-column prop="je" sortable label="金额" width="80">
          </el-table-column>

          <el-table-column  prop="ckmc"  sortable label="仓库" width="80">
          </el-table-column>
          <el-table-column  prop="ph"  sortable label="批号" width="80">
          </el-table-column>
          <el-table-column prop="rq" sortable label="时间" width="100">
          </el-table-column>
          <el-table-column prop="txm" sortable label="条码" width="100">
          </el-table-column>
          <el-table-column prop="cjhh" sortable label="货号" width="100">
          </el-table-column>
          
          <el-table-column prop="ChengBen" sortable label="成本" width="100">
          </el-table-column>

        </el-table>
      </div>
    </div>

    <div class="table-box table-user">
      <div class="block" v-for="ck in ckdtList" :key="ck.id">
        <el-avatar size="small" :src="ck.headIcon" class="ava"></el-avatar>
        <div class="name">{{ ck.username }}</div>
        <span class="time">{{ ck.clicksj }}</span>
      </div>
    </div>

  </div>
</template>

<script>
import * as moment from "moment";
import XLSX from "xlsx";
export default {
  data() {
    return {
      dialogFormVisible: false,
      ChanPinIsT: false,
      isZengPin: false,
      tableLTloading: false,

      xiaoliang: 5000,
      maoli: 400,
      xiaoliangB:5000,
      maoliB:400,
      kssj: "",
      jssj: "",
      typeList: [
        {
          value: "销售出库",
          id: "45",
        },
        {
          value: "采购入库",
          id: "0",
        },
        {
          value: "拒收入库",
          id: "1",
        },
        {
          value: "退货入库",
          id: "2",
        }
      ],
      typeValue: "",
      showRequestFlag: true,
      productList: [],
      customerList: [],
      CompanyValue: "",
      companyList: [
        {
          value: "卖场",
          id: "1",
        },
        {
          value: "连锁",
          id: "2",
        },
        {
          value: "直营批发",
          id: "3",
        },
      ],
      value: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      MenDianIsT: false,
      value1: "",
      value2: "",
      CPdtList: [],
      FLdtList: [],
      GYSdtList: [],
      PPdtList: [],
      ckdtList: [],
      CPdtValue: "",
      FLdtValue: "",
      GYSdtValue: "",
      PPdtValue: "",
      MCdtList: [],
      DQdtList: [],
      YWdtList: [],
      QYdtList: [],
      MCdtValue: "",
      DQdtValue: "",
      YWdtValue: "",
      QYdtValue: "",
      MenDianT: "",
      ChanPinT: "",
      ZongBiao: [],

      listItems: [
        { label: "单号" },
        { label: "客户" },
        { label: "产品" },
        { label: "数量" },
        { label: "箱数" },
        { label: "金额" },
        { label: "仓库" },
        { label: "批号" },
        { label: "时间" },
        { label: "条码" },
        { label: "货号" },
        { label: "成本" },
      ],
      token: "",
      where:{}
    };
  },
  mounted() {
    this.token = this.storage.get("userToken");
    if (!this.token) {
      this.$router.push("/pcLogin");
    }
    this.jssj = moment(new Date()).format("YYYY-MM-DD");

    this.changeDayNum(30);

    this.getChanPin();
    this.getMenDian();
  },
  methods: {

    //点击表格行触发事件
    //row 包含该行中所有的数据
    rowClick(row, column, event) {
      // switch (column.property) {
      //   case "PuShiShu":
      //     this.where.cpbh=row.cpbh;
      //     this.MenDianFenJietabs= "XiaoShouMenDian";
      //     this.where.method='PuShiMenDian';
      //     this.axios
      //       .get(`/App/NBwtsp/ShuJuZhongXin.ashx`, {
      //         params: this.where,
      //       })
      //       .then((res) => {
      //         if (res.data.Success) {

      //           this.MenDianFenJieData = JSON.parse(res.data.Data);
      //           this.dialogFormVisible = true;
      //         } else {
      //           alert(res.data.Message);
      //         }
      //       });
      //     console.log(row.cpbh);

      //     break;
      // }
      //console.log(row,column,event);
    },
    //弹出窗的导出
    exportDialog() {},
    exportNewList() {
      //基础设置
      var xlsxParam = { raw: true }; //转换成excel时，使用原始的格式
      var tabHandle = []; //导出表头
      // this.listItems 是表头数据
      this.listItems.forEach((item) => {
        tabHandle.push(item.label);
      }); //表头数组

      let tableData2 = [];

      tableData2.push(tabHandle);


      this.ZongBiao.forEach((item, index) => {
        let rowData = [];
        //导出内容的字段
        rowData = [
          item.dh,
          item.wldw,
          item.cpmc,
          item.sl,
          item.sldesp,
          item.je,
          item.ckmc,
          item.ph,
          item.rq,
          item.txm,
          item.cjhh,
          item.ChengBen,
        ];
        tableData2.push(rowData);
      });

      let workSheet2 = XLSX.utils.json_to_sheet(tableData2, xlsxParam);
      let bookNew = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(bookNew, workSheet2, "出入库流水");
      let name = "出入库流水.xlsx"; //表格名称
      XLSX.writeFile(bookNew, name); // 保存的文件名
    },
    //时间间隔更改
    changeDayNum(e) {
      // console.log(111)
      //console.log(e)
      if (this.jssj) {
        var nowDate = moment(this.jssj);
        var dateFrom = nowDate.subtract(e, "d").format("YYYY-MM-DD");
        this.kssj = dateFrom;
      }
    },
    handleClick(tab, event) {
      //分解切换
      console.log(tab, event);
    },
    //选择开始时间
    changeKS(e) {
      // if (this.dayValue) {
      //   var nowDate = moment(e);
      //   var dateFrom = nowDate.add(this.dayValue, "d").format("YYYY-MM-DD");
      //   console.log(dateFrom);

      //   this.jssj = dateFrom;
      // }
      // this.kssj = moment(e).format("YYYY-MM-DD");
    },
    //选择结束时间
    changeJS(e) {
      // if (this.dayValue) {
      //   var nowDate = moment(e);
      //   var dateFrom = nowDate
      //     .subtract(this.dayValue, "d")
      //     .format("YYYY-MM-DD");
      //   this.kssj = dateFrom;
      // }
      // this.jssj = moment(e).format("YYYY-MM-DD");
    },
    getChanPin() {
      this.axios
        .get(
          `${this.$dt}/App/NBwtsp/ShuJuZhongXin.ashx?method=XiaZaiSuoYouChanPin&BaoBiaoMingCheng=ChuRuKuLiuShuiZhang&token=` +
            this.token
        )
        .then((res) => {
          if (res.status == 200) {
            this.CPdtList = res.data.CPdt;
            this.FLdtList = res.data.FLdt;
            this.GYSdtList = res.data.GYSdt;
            this.PPdtList = res.data.PPdt;
            this.ckdtList = res.data.ckdt;
          }
        });
    },
    getMenDian() {
      this.axios
        .get(
          `${this.$dt}/App/NBwtsp/ShuJuZhongXin.ashx?method=XiaZaiSuoYouMenDian&token=` +
            this.token
        )
        .then((res) => {
          if (res.status == 200) {
            this.MCdtList = res.data.MCdt;
            this.DQdtList = res.data.DQdt;
            this.YWdtList = res.data.YWdt;
            this.QYdtList = res.data.QYdt;
          }
        });
    },
    // 错误消息提示
    openError(errMessage) {
      this.$message({
        showClose: true,
        message: errMessage,
        type: "warning",
      });
    },
    //查询
    toQuery() {
      // this.showRequestFlag = false;
      let YeWuYuan = "";
      for (var i = 0; i < this.YWdtValue.length; i++) {
        YeWuYuan = YeWuYuan + "'" + this.YWdtValue[i] + "'";
        if (i + 1 != this.YWdtValue.length) {
          YeWuYuan = YeWuYuan + ",";
        }
      }
  let LeiXing = "";
      for (var i = 0; i < this.typeValue.length; i++) {
        LeiXing = LeiXing + "'" + this.typeValue[i] + "'";
        if (i + 1 != this.typeValue.length) {
          LeiXing = LeiXing + ",";
        }
      }


      let QuYu = ""; //区域
      for (var i = 0; i < this.QYdtValue.length; i++) {
        QuYu = QuYu + "'" + this.QYdtValue[i] + "'";
        if (i + 1 != this.QYdtValue.length) {
          QuYu = QuYu + ",";
        }
      }
      let PinPai = ""; //品牌
      for (var i = 0; i < this.PPdtValue.length; i++) {
        PinPai = PinPai + "'" + this.PPdtValue[i] + "'";
        if (i + 1 != this.PPdtValue.length) {
          PinPai = PinPai + ",";
        }
      }

      // console.log(YeWuYuan);
      this.ZongBiao = [];

      this.tableLTloading = true;
      if (!this.kssj) {
        this.openError("未选择开始时间");
        return;
      }
      if (!this.jssj) {
        this.openError("未选择结束时间");
        return;
      }

      if (this.MenDianIsT) {
        if (!this.MenDianT) {
          this.openError("未填写门店");
          return;
        }
      }
      if (this.ChanPinIsT) {
        if (!this.ChanPinT) {
          this.openError("未填写产品");
          return;
        }
      }
this.where= {
            method: "ChuRuKuLiuShuiZhang",
            kssj: this.kssj,
            jssj: this.jssj,
            isZengPin: this.isZengPin ? "1" : "0",
            ZongGongSi: this.CompanyValue.join(","),
            DaQu: this.DQdtValue.join(","),
            YeWuYuan: YeWuYuan,
            QuYu: QuYu,
            MenDian: this.MCdtValue.join(","),
            MenDianT: this.MenDianT,
            MenDianIsT: this.MenDianIsT ? "1" : "0",
            FenLei: this.FLdtValue.join(","),
            ChangJia: this.GYSdtValue.join(","),
            PinPai: PinPai,
            ChanPin: this.CPdtValue.join(","),
            ChanPinT: this.ChanPinT,
            ChanPinIsT: this.ChanPinIsT ? "1" : "0",
            LeiXing:LeiXing,
            token: this.token,
          };

      this.axios
        .get(`${this.$dt}/App/NBwtsp/ShuJuZhongXin.ashx`, {
          params: this.where,
        })
        .then((res) => {
          // console.log(res);

          this.tableLTloading = false;
          if (res.data.Success) {

            this.ZongBiao = JSON.parse(res.data.Data);
            //得到总表，然后根据平均值取四个表

          } else {
            alert(res.data.Message);
          }
        });
    },

  },
};
</script>

<style scoped lang="scss">
.container {
  // width: 100%;
  // background: #fff;
  // padding: 40px;
  // background-color: #409eff; /* 浏览器不支持时显示 */
  // background-image: linear-gradient(#409eff,#409eff,#fff);
  // min-width: 1200px;

  background: #f9fafc;
  .select-box {
    display: flex;
    flex-direction: column;
    // background:#eee;
    .select-top,
    .select-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 5px;
      .select-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        // width: 200px;
      }
    }
    .select-top {
      // background-color: #409eff;

      color: #333;
      justify-content: space-between;

      .select-date-box,
      .select-indicators-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 495px;
      }
      .select-indicators-box {
        justify-content: flex-end;
      }
    }
    .select-content {
      margin: 5px 0;
      .select-item {
        width: 18%;
      }
      .mendian-item {
        width: 40%;
      }
    }
    .select-content2 {
      .select-item {
        width: 20%;
        &:last-child {
          width: 40%;
        }
        .check-box {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }
    }
  }
  .table-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: calc(100% - 80px);
    // padding: 0 40px;
    margin: 0px 40px;
    .table-left,
    .table-right {
      width: 100%;
    //   max-width: 862px;
      // margin: 40px;
    }
    .table-left {
    //   margin-left: 30px;
    }
    .table-right {
    //   margin-right: 30px;
    }
  }
}
.select-desc {
  display: block;
  width: 80px;
  text-align: right;
  flex-shrink: 0;
}
.indicators-content {
  background-color: #fff;
  width: 110px;
  height: 30px;
  line-height: 30px;
  border-radius: 6px;
  padding-left: 6px;
}

.day-num-box /deep/ .el-input {
  width: 90px;
}
.day-num-box {
  margin-left: 20px;
}
.date-box /deep/ .el-date-editor.el-input {
  width: 100%;
  // min-width: 120px;
}
.date-box /deep/ .el-input--suffix .el-input__inner {
  padding-right: 0;
}
// .check-box {
//   margin-left: 20px;
// }
.check-box /deep/ .el-checkbox.is-bordered {
  margin-right: 0;
}
.select-content /deep/ .el-checkbox {
  margin-left: 5px;
}
.container /deep/ .el-select__tags {
  flex-wrap: nowrap;
  overflow: hidden;
}
.operate-box {
  padding: 10px;
  color: #fff;
  // color: #333;
  // background-image: linear-gradient(
  //   to bottom,
  //   rgba(240, 242, 245, 0.4),
  //   rgba(202, 205, 207, 0.4),
  //   rgba(240, 242, 245, 0.4)
  // );
  background: #66b1ff;
  cursor: pointer;
}
.el-icon-d-arrow-left,
.el-icon-d-arrow-right {
  transform: rotate(90deg);
}
.show-content {
  // background: rgba(255,255,255,.4);
  background: #f9fafc;
}
.btns {
  margin-bottom: 20px;
}
.product-input,
.mendian-input {
  width: 100%;
  height: 38px;
  border: 1px solid #eee;
  border-radius: 6px;
  padding-left: 10px;
  &::placeholder {
    color: #c8c9cc;
  }
}
.table-title {
  width: 100%;
  position: relative;

  border-bottom: 1px solid #eee;
  .table-download {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }
}
.container /deep/ .el-select {
  width: 100%;
}
.product-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 40% !important;
}
.select-content /deep/ .el-button {
  margin-left: 5px;
}
.table-box /deep/ .cell {
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
}
.table-user{
  display: flex;
  flex-direction: row;
  align-items: center;
      flex-direction: row;
    flex-wrap: wrap;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  .block{
    display: flex;
    flex-direction: column;
    align-items:center;
    .ava,.name,.time{
      margin-top: 3px;
    }
    .name{
          font-size: 8px;
      display: flex;
      flex-direction:column;
      justify-content:space-around;
    }
    .time{
         font-size: 8px;
         padding: 3px;
    }
  }
}
</style>
