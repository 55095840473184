<template>
  <div class="mobile-container">
    <div class="mobile-header">
      <span class="head-line">万商平台-数据中心</span>
      <span class="sub-title">问道专业大数据 | 抢占市场新效益</span>
    </div>

    <div class="data-content">
      <div class="data-left">
        <div
          class="data-item"
          :class="[item.hasPermission ? '' : 'no-bg']"
          v-for="(item, index) in leftList"
          :key="index"
        >
          <span
            class="data-index"
            :class="[item.hasPermission ? '' : 'inner-noP']"
            >{{ item.id }}</span
          >
          <div class="data-inner">
            <router-link
              class="inner-big"
              v-if="item.hasPermission && item.hasPermission == true"
              :to="{ name: item.component, params: {} }"
            >
              {{ item.name }}
            </router-link>
            <span class="inner-big inner-noP" v-else>{{ item.name }}</span>
            <router-link
              class="inner-small"
              v-if="item.hasPermission && item.hasPermission == true"
              :to="{ name: item.component, params: {} }"
            >
              {{ item.JianJie }}
            </router-link>
            <span class="inner-small inner-noP" v-else>{{ item.JianJie }}</span>
          </div>
        </div>
      </div>
      <div class="data-right">
        <div
          class="data-item"
          :class="[item.hasPermission ? '' : 'no-bg']"
          v-for="(item, index) in rightList"
          :key="index"
        >
          <span
            class="data-index"
            :class="[item.hasPermission ? '' : 'inner-noP']"
            >{{ item.id }}</span
          >
          <div class="data-inner">
            <router-link
              class="inner-big"
              v-if="item.hasPermission && item.hasPermission == true"
              :to="{ name: item.component, params: {} }"
            >
              {{ item.name }}
            </router-link>
            <span class="inner-big inner-noP" v-else>{{ item.name }}</span>
            <router-link
              class="inner-small"
              v-if="item.hasPermission && item.hasPermission == true"
              :to="{ name: item.component, params: {} }"
            >
              {{ item.JianJie }}
            </router-link>
            <span class="inner-small inner-noP" v-else>{{ item.JianJie }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "mDataCenter",
  data() {
    return {
      tabList: [
        {
          name: "商业智能BI",
          component: "PivotTable",
          JianJie: "销售数据全透视分析",
          hasPermission: false,
          id: 1,
        },
        {
          name: "库存查询",
          component: "KuCunChaXun",
          JianJie: "实际库存及时掌控",
          hasPermission: false,
          id: 2,
        },
        {
          name: "薪酬计效",
          component: "",
          JianJie: "动态了解薪资情况",
          hasPermission: false,
          id: 3,
        },
        {
          name: "运输情况",
          component: "",
          JianJie: "了解公司装车运输情况",
          hasPermission: false,
          id: 4,
        },
        {
          name: "产品四象限分析",
          component: "",
          JianJie: "优化产品铺市情况",
          hasPermission: false,
          id: 5,
        },
        {
          name: "客户四象限分析",
          component: "",
          JianJie: "优化门店产品结构",
          hasPermission: false,
          id: 6,
        },
        {
          name: "出入库明细",
          component: "",
          JianJie: "实时出入库明细",
          hasPermission: false,
          id: 7,
        },
        {
          name: "不良品报表",
          component: "",
          JianJie: "有效期过大分析",
          hasPermission: false,
          id: 8,
        },
        {
          name: "缺货分析报表",
          component: "",
          JianJie: "低于安全库存",
          hasPermission: false,
          id: 5,
        },
      ],
      leftList: [],
      rightList: [],
      token: "",
    };
  },
  mounted() {
    if (!this.$isMobile()) {
        this.$alert('您当前在电脑上查看，是否切换？', '提示', {
          confirmButtonText: '确定',
          callback: action => {
            this.$router.push("/");
          }
        });

    }

    this.token = this.storage.get("userToken");
    if (!this.token) {
      this.$router.push("/pcLogin");
    }

    this.axios
      .get(
        `${this.$dt}/App/NBwtsp/ShuJuZhongXin.ashx?method=getHuiYuanSJZX&token=` +
          this.token
      )
      .then((res) => {
        if (res.data.Success) {
          this.Udata = res.data;
          this.watermark({ text: this.Udata.CanShu1, fontSize: "20px" });
          this.QXdt = JSON.parse(res.data.Data);

          this.QuanXian = this.QXdt[0].QuanXian.split(",");
          for (let j = 0; j < this.tabList.length; j++) {
            if (this.tabList[j].component != "") {
              if (this.QuanXian.indexOf(this.tabList[j].component) != -1) {
                this.tabList[j].hasPermission = true;
              }
            }
          }

          this.storage.set("QXdt", JSON.stringify(this.QXdt));
          this.storage.set("QuanXian", JSON.stringify(this.QuanXian));
        } else {
          this.storage.set("userToken", "");
          this.$message({
            message: res.data.Message,
            type: "warning",
          });
          this.$router.push("/pcLogin");
        }
      });
    this.leftList = this.tabList.filter((item, index, arr) => {
      return index % 2 == 0;
    });
    this.rightList = this.tabList.filter((item, index, arr) => {
      return index % 2 != 0;
    });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.mobile-container {
  background-color: #020303;
  //   width: 23.4375rem;
  width: 100%;
  min-width: 23.4375rem;
  height: 100vh;
}
.mobile-header {
  width: 100%;
  padding: 1.96875rem 0 1.25rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  .head-line {
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #00f1fa;
    -webkit-text-stroke: 1px #07e8e9;
    text-stroke: 1px #07e8e9;

    background: linear-gradient(0deg, #006b98 0%, #00f1fa 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.7025rem;
  }
  .sub-title {
    font-size: 1.21875rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #00f1fa;
    margin-top: 0.90625rem;
    padding-right: 0;
  }
}
.data-content {
  margin: 0 1rem 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .data-left {
    .data-item {
      background: url("../../assets/images/data_left_normal.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      .data-index {
        top: 1.8rem;
        left: 1.2rem;
      }
      .data-inner {
        top: 1.2rem;
        left: 3rem;
      }
    }
    .no-bg {
      background-image: url("../../assets/images/data_left_ban.png") !important;
    }
  }
  .data-right {
    margin-top: 2rem;
    .data-item {
      background: url("../../assets/images/data_right_normal.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      .data-index {
        top: 1.8rem;
        right: 1.2rem;
      }
      .data-inner {
        top: 1.2rem;
        right: 3rem;
      }
    }
    .no-bg {
      background-image: url("../../assets/images/data_right_ban.png") !important;
    }
  }
  .data-item {
    margin-top: 0.6rem;
    width: 10.4rem;
    height: 6rem;
    position: relative;
    .inner-noP {
      color: #8d8e8e !important;
    }
    .data-index {
      position: absolute;

      font-size: 1.03125rem;
      color: #0bb6db;
      font-weight: bold;
    }
    .data-inner {
      position: absolute;
      width: 6.4rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .inner-big {
        font-size: 0.875rem;
        color: #94e9ff;
        font-weight: bold;
      }
      .inner-small {
        margin-top: 0.40625rem;
        font-size: 0.53125rem;
        color: #7ac2d4;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }
    }
  }
}
</style>
