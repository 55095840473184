<template>
  <div class="container">
    <header>
      <span>万商平台-数据中心</span>
      <div class="header-right">
        <span>{{Udata.CanShu1}}</span>
        <button @click="logout">退出</button>
      </div>
    </header>


    <div class="container-inner">
      <div class="tab-list">
        <transition :name="'slide-fade'+(index+1)" v-for="(item,index) in tabObj.tabArr" :key="index">
          <div class="tab-item" :class="[item.hasPermission?'':'no-bg']"  v-show="selectedIndex!==99" v-if="index<4">
            <div v-if="item.url">
              <a class="tab-title" :href="item.url">{{item.name}}</a>
            </div>
            <div v-else>
              <router-link class="tab-title" v-if="item.hasPermission&&item.hasPermission==true" :to="{'name':'pcContent','params':{
              'neme':item.component,
              'title':item.name
            }}">
                {{item.name}}
            </router-link>
            <span class="tab-title-noP" v-else>{{item.name}}</span>
            </div>


            <div class="tab-content" v-if="item.url">
              <a :href="item.url">{{item.JianJie}}</a>
            </div>
            <div class="tab-content" v-else>
              <router-link class="content-item" v-if="item.hasPermission&&item.hasPermission==true" :to="{'name':'pcContent','params':{
                'neme':item.component,
                'title':item.name
              }}">
                  {{item.JianJie}}
              </router-link>
              <span class="no-permission" v-else>{{item.JianJie}}</span>
            </div>


          </div>
        </transition>
      </div>

      <div class="container-center">

        <div class="center-progrem center-progrem1" @click="changeStyle(0)"
          :class="selectedIndex == 0 ? 'hasSelected' : ''">
          <div class="progrem-inner"></div>
          <div class="progrem-title">产品</div>
        </div>
        <div class="center-progrem center-progrem2" @click="changeStyle(1)"
          :class="selectedIndex == 1 ? 'hasSelected' : ''">
          <div class="progrem-inner"></div>
          <div class="progrem-title">仓库</div>
        </div>
        <div class="center-progrem center-progrem3" @click="changeStyle(2)"
          :class="selectedIndex == 2 ? 'hasSelected' : ''">
          <div class="progrem-inner"></div>
          <div class="progrem-title">销售</div>
        </div>
        <div class="center-progrem center-progrem4" @click="changeStyle(3)"
          :class="selectedIndex == 3 ? 'hasSelected' : ''">
          <div class="progrem-inner"></div>
          <div class="progrem-title">客户</div>
        </div>
        <div class="center-progrem center-progrem5" @click="changeStyle(4)"
          :class="selectedIndex == 4 ? 'hasSelected' : ''">
          <div class="progrem-inner"></div>
          <div class="progrem-title">财务</div>
        </div>
        <div class="center-progrem center-progrem6" @click="changeStyle(5)"
          :class="selectedIndex == 5 ? 'hasSelected' : ''">
          <div class="progrem-inner"></div>
          <div class="progrem-title">采购</div>
        </div>
        <div class="center-inner"></div>
      </div>

      <div class="tab-list">
        <transition :name="'slide-fade'+(index+1)" v-for="(item,index) in tabObj.tabArr" :key="index">
          <div class="tab-item" :class="[item.hasPermission?'':'no-bg']"  v-show="selectedIndex!==99" v-if="index>=4">
            <div v-if="item.url">
              <a class="tab-title" :href="item.url">{{item.name}}</a>
            </div>
            <div v-else>
            <router-link class="tab-title" v-if="item.hasPermission&&item.hasPermission==true" :to="{'name':'pcContent','params':{
              'neme':item.component,
              'title':item.name
            }}">
                {{item.name}}
            </router-link>
            <span class="tab-title-noP" v-else>{{item.name}}</span>
           </div>


           <div class="tab-content" v-if="item.url">
              <a :href="item.url">{{item.JianJie}}</a>
            </div>
            <div class="tab-content" v-else>
              <router-link class="content-item" v-if="item.hasPermission&&item.hasPermission==true" :to="{'name':'pcContent','params':{
                'neme':item.component,
                'title':item.name
              }}">
                  {{item.JianJie}}
              </router-link>
              <span class="no-permission" v-else>{{item.JianJie}}</span>
            </div>
          </div>

        </transition>



      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DataCenter",
  data() {
    return {
      selectedIndex: 99,
      isX: false,
      tabObj: {},
      tabList: [
        {
          tabName: '产品',
          tabArr: [
            {
              name: "产品四象限分析",
              component: "pcReport",
              JianJie: "快速找出高销售低毛利产品，指导产品结构优化",
              hasPermission: false,

            },

            {
              name: "新品跟踪报表",
              component: "",
              JianJie: "新品有可能关注度不够，铺户不够而黄的跟需要持续跟踪",
              hasPermission: false,

            },
            {
              name: "拟定淘汰产品",
              component: "",
              JianJie: "分析拟定淘汰产品中是否还有翻盘的可能",
              hasPermission: false,

            }, {
              name: "产品周转查询",
              component: "",
              JianJie: "产品周转率直接影响着对这个产对的决策。",
              hasPermission: false,

            },
            {
              name: "产品四象限分析(铭盛)",
              component: "pcReportMS",
              JianJie: "快速找出高销售低毛利产品，指导产品结构优化",
              hasPermission: false,
            },
            {
              name: "产品四象限分析(舟山)",
              component: "pcReportZS",
              JianJie: "快速找出高销售低毛利产品，指导产品结构优化",
              hasPermission: false,
            }
          ]
        },
        {
          tabName: '仓储物流',
          tabArr: [
            {
              name: "库存查询",
              component: "KuCunChaXun",
              JianJie: "供厂家与外出员工快速查询实时库存",
              hasPermission: false,
            },
            {
              name: "仓库薪酬计效",
              component: "",
              JianJie: "仓库薪酬数据化展示",
              hasPermission: false,
            },
            {
              name: "库位效率报表",
              component: "",
              JianJie: "每一个库位都很宝贵需要将出入大的产对放在方便的库位",
              hasPermission: false,
            }, {
              name: "运输单装车表",
              component: "",
              url:"http://oa.28888008.com/dpm/2/",
              JianJie: "在仓库显示方便自提与装车人员及时了解情况",
              hasPermission: true,
            }, {
              name: "待排车报表",
              component: "",
              url:"http://oa.28888008.com/dpm/3/5.html",
              JianJie: "订单实时不停更新排车人员要不可能让",
              hasPermission: false,
            }, {
              name: "效期风险提醒大屏",
              component: "",
              url:"http://oa.28888008.com/dpm/#/view/8",
              JianJie: "产品有效期达到预警值时及时显示方便通道责任人",
              hasPermission: true,
            }, {
              name: "装卸复检情况大屏",
              component: "",
              url:"http://oa.28888008.com/dpm/#/view/7",
              JianJie: "显示装卸复检等计件数据",
              hasPermission: true,
            }, {
              name: "拣货补货情况大屏",
              component: "",
              url:"http://oa.28888008.com/dpm/#/view/6",
              JianJie: "显示拣货补货等计件数据,显示等待人员",
              hasPermission: true,
            },
          ]
        },
        {
          tabName: '销售',
          tabArr: [
            {
              name: "出入库流水",
              component: "laundrylist",
              JianJie: "实时出入库存流水明细",
              hasPermission: false,
            },
            {
              name: "BI销售数据透视表",
              component: "PivotTable",
              JianJie: "销售数据一张表就够了",
              hasPermission: false,
            },
            {
              name: "经营总表",
              component: "",
              JianJie: "公司销售全局数据",
              hasPermission: false,
            },
            {
              name: "缺货品项跟踪",
              component: "",
              JianJie: "很多原因会造成缺货要充份了解缺货原因，并解决问题。",
              hasPermission: false,
            },
          ]
        },
        {
          tabName: '客户',
          tabArr: [
            {
              name: "客户四象限分析",
              component: "KeHuSiXiang",
              JianJie: "充分了解产品在门店的铺市情况，直接显示销量差门店的核心问题",
              hasPermission: false,
            }, {
              name: "业务门店拜访率",
              component: "",
              JianJie: "及时了解业务员门店拜访率，以及排名情况",
              hasPermission: false,
            }, {
              name: "业务门店计划执行率",
              component: "",
              JianJie: "了解业务门店计划拜访执行率",
              hasPermission: false,
            }, {
              name: "业务门店拜访统计表",
              component: "",
              JianJie: "展示业务门店拜访次数以及失访客户",
              hasPermission: false,
            }, {
              name: "已流失的客户",
              component: "",
              JianJie: "本月已无销量客户，结合拜访时间定性为已流失的客户",
              hasPermission: false,
            },
            {
              name: "客户四象限分析(铭盛)",
              component: "KeHuSiXiangMS",
              JianJie: "充分了解产品在门店的铺市情况，直接显示销量差门店的核心问题",
              hasPermission: false,
            },
            {
              name: "客户四象限分析(舟山)",
              component: "KeHuSiXiangZS",
              JianJie: "充分了解产品在门店的铺市情况，直接显示销量差门店的核心问题",
              hasPermission: false,
            }
          ]
        },
        {
          tabName: '财务',
          tabArr: [
            {
              name: "开票薪酬计效",
              component: "",
              JianJie: "开单、审核、下发指令计件数据",
              hasPermission: false,
            }, {
              name: "应收款日利息",
              component: "",
              JianJie: "各部门日结利息分析",
              hasPermission: false,
            }, {
              name: "促销费用报表",
              component: "",
              JianJie: "代垫费用是经销商重要支出如果不分析到位怎么亏都不知道",
              hasPermission: false,
            }, {
              name: "业务组费用承担",
              component: "",
              JianJie: "每个业员组应当清楚自己组所承担的分用去向",
              hasPermission: false,
            },
          ]
        },
        {
          tabName: '采购',
          tabArr: [
            {
              name: "快速割箱报表",
              component: "GeXiang",
              JianJie: "产品效期过三分之一后就很难动，故需要提交跟踪处理",
              hasPermission: false,
            },
            {
              name: "三分之一跟踪",
              component: "SanFenZhiYiGenZong",
              JianJie: "快速找出高销售低毛利产品，指导产品结构优化",
              hasPermission: false,
            },
            {
              name: "7天可销",
              component: "TianKeXiao",
              JianJie: "缺货可能面临罚款，所有产品要确保几天安全库存",
              hasPermission: false,
            },
          ]
        },
      ],
      QuanXian: [],
      QXdt: [],
      Udata: {},
      token:''
    };
  },
  mounted() {
    if (this.$isMobile()) {
      this.$alert('您当前在手机上查看，是否切换？', '提示', {
          confirmButtonText: '确定',
          callback: action => {
            this.$router.push("/mDataCenter");
          }
        });

    }

    this.token = this.storage.get("userToken");
    if (!this.token) {
      this.$router.push("/pcLogin");
    }


    this.axios
      .get(
        `${this.$dt}/App/NBwtsp/ShuJuZhongXin.ashx?method=getHuiYuanSJZX&token=` +
        this.token
      )
      .then((res) => {

        if (res.data.Success) {
          this.Udata = res.data;
          this.watermark({ text: this.Udata.CanShu1, fontSize: '20px' });
          this.QXdt = JSON.parse(res.data.Data);

          this.QuanXian = this.QXdt[0].QuanXian.split(",");
          for (let i = 0; i < this.tabList.length; i++) {
            for (let j = 0; j < this.tabList[i].tabArr.length; j++) {

              if(this.tabList[i].tabArr[j].component!=""){
                if (this.QuanXian.indexOf(this.tabList[i].tabArr[j].component) != -1) {
                  this.tabList[i].tabArr[j].hasPermission=true;
                }
              }

            }


          }

          this.storage.set("QXdt",JSON.stringify(this.QXdt));
          this.storage.set("QuanXian",JSON.stringify(this.QuanXian));


        } else {
          this.storage.set("userToken", "");
          this.$message({
            message: res.data.Message,
            type: "warning",
          });
          this.$router.push("/pcLogin");
        }
      });


  },
  methods: {
    changeStyle(index) {
      this.selectedIndex = 99;
      setTimeout(() => {
        this.selectedIndex = index;
        this.tabObj = this.tabList[index];
      }, 100)
    },
    //点击退出
    logout() {
      this.storage.set("userToken", "");

      this.$router.push("/pcLogin");
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}

.container {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/center_bg.png");
  background-size: 100% 100%;
  background-color: #010b21;
  padding-top: 10px;
  overflow: scroll;
  min-width: 1020px;

  header {
    position: relative;
    height: 4.3rem;
    width: 100%;
    background-image: url("../../assets/images/center_header.png");
    background-size: 100% 100%;

    span {
      display:inline-block;
      width: 100%;
      font-family: SourceHanSansCN-Bold;
      font-size: 2rem;
      font-weight: bold;
      line-height: 3.8rem;
      text-align: center;
      letter-spacing: 0.054000000000000006em;
      color: #ffffff;
    }
  }

  .container-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;

    .tab-list {
      display: flex;
      flex-direction: column;
      width: 20vw;
      .no-bg{
        background-image: url("../../assets/images/tab_item-ON.png") !important;

      }
      .tab-item {
        width: 20vw;
        // width: 375px;
        height: 8rem;
        background-image: url("../../assets/images/tab_item.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-bottom: 1.4rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;


        .content-item {
          color: #ffffff;
          line-height: 2rem;
        }

        .content-item:active {
          color: #ecc832;
        }

        .tab-title {
          font-weight: bold;
          font-size: 1.18rem;
          color: #ffffff;
          margin-left: 2.4rem;
          line-height: 2.18rem;
          margin-bottom: 1.4rem;
          width: calc(100% - 2.4rem);
          text-align: left;
        }

        .tab-title-noP {
          font-weight: bold;
          font-size: 1.18rem;
          color: #c8c9cc;
          margin-left: 2.4rem;
          line-height: 2.18rem;
          margin-bottom: 1.4rem;
          width: calc(100% - 2.4rem);
          text-align: left;
        }

        .tab-content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin: 0.8rem 0.8rem;
          width: calc(100% - 1.6rem);
          // overflow-y: scroll;
          // height: calc(100% - 3.58rem);

        }
      }


    }

    .container-center {
      position: absolute;
      top: 50px;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 50.75vw;
      height: 85vh;

      .progress {
        position: absolute;
        width: 3.15rem;
        height: 2.25rem;
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }

      .progress1 {
        transform: rotate(-7deg);
        left: 16.81rem;
        top: 11.45rem;
        background-image: url("../../assets/images/progress1.png");
      }

      .progress2 {
        transform: rotate(-deg);
        left: 35.56rem;
        top: 11.83rem;
        background-image: url("../../assets/images/progress2.png");
      }

      .progress3 {
        transform: rotate(-29deg);
        left: 47.74rem;
        top: 25.25rem;
        background-image: url("../../assets/images/progress3.png");
      }

      .progress4 {
        transform: rotate(-22deg);
        left: 35.56rem;
        top: 36.76rem;
        background-image: url("../../assets/images/progress4.png");
      }

      .progress5 {
        transform: rotate(8deg);
        left: 16.81rem;
        top: 36.96rem;
        background-image: url("../../assets/images/progress4.png");
      }

      .center-progrem {
        position: absolute;
        width: 9.19rem;
        height: 9.19rem;
        z-index: 999;
        background-image: url("../../assets/images/progrem_bg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        cursor: pointer;

        .progrem-title {
          width: 6.06rem;
          height: 2.625rem;
          position: absolute;
          top: -3.94rem;
          left: 0;
          right: 0;
          margin: auto;
          line-height: 2.625rem;
          font-family: SourceHanSansCN-Bold;
          font-size: 1.75rem;
          font-weight: bold;
          text-align: center;
          color: #ffffff;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-image: url("../../assets/images/progrem_title.png");
        }

        .progrem-inner {
          width: 2.4rem;
          height: 2.4rem;
          position: absolute;
          top: 0.9375rem;
          left: 0px;
          right: 0;
          margin: auto;
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }

      .center-progrem.hasSelected {
        background-image: url("../../assets/images/progrem_selected_bg.png");
      }

      .center-progrem.hasSelected .progrem-title {
        color: #ecc832;
        background-image: url("../../assets/images/progrem_selected_title.png");
      }

      .center-progrem1 {
        left: 1.4vw;
        top: 24.7vh;

        .progrem-inner {
          background-image: url("../../assets/images/pg1.png");
        }
      }

      .center-progrem2 {
        left: 19.94vw;
        top: 8.17vh;

        .progrem-inner {
          background-image: url("../../assets/images/pg2.png");
        }
      }

      .center-progrem3 {
        left: 39.81vw;
        top: 25.75vh;

        .progrem-inner {
          background-image: url("../../assets/images/pg3.png");
        }
      }

      .center-progrem4 {
        left: 37.81vw;
        top: 48.5vh;

        .progrem-inner {
          top: 0.4375rem;
          height: 3.44rem;
          width: 3.75rem;
          background-image: url("../../assets/images/pg4.png");
        }

        .progrem-title {
          top: 9.19rem;
        }
      }

      .center-progrem5 {
        left: 19.94vw;
        top: 58vh;

        .progrem-inner {
          background-image: url("../../assets/images/pg5.png");
        }

        .progrem-title {
          top: 9.19rem;
        }
      }

      .center-progrem6 {
        left: 3.9vw;
        top: 50.5vh;

        .progrem-inner {
          background-image: url("../../assets/images/pg6.png");
        }

        .progrem-title {
          top: 9.19rem;
        }
      }

      .center-inner {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 44.4vw;
        height: 54.6vh;
        background-image: url("../../assets/images/center_inner.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }
  }
}

.slide-fade1-enter-active {
  transition: all 0.2s ease 0s;
}

.slide-fade2-enter-active {
  transition: all 0.2s ease 0.2s;
}

.slide-fade3-enter-active {
  transition: all 0.2s ease 0.4s;
}

.slide-fade4-enter-active {
  transition: all 0.2s ease 0.6s;
}

.slide-fade5-enter-active {
  transition: all 0.2s ease 0.8s;
}

.slide-fade6-enter-active {
  transition: all 0.2s ease 1s;
}

.slide-fade7-enter-active {
  transition: all 0.2s ease 1.2s;
}

.slide-fade8-enter-active {
  transition: all 0.2s ease 1.4s;
}

.slide-fade9-enter-active {
  transition: all 0.2s ease 1.6s;
}

.slide-fade10-enter-active {
  transition: all 0.2s ease 1.8s;
}

.slide-fade1-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1) 1.8s;
}

.slide-fade2-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1) 1.6s;
}

.slide-fade3-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1) 1.4s;
}

.slide-fade4-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1) 1.2s;
}

.slide-fade5-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1) 1.0s;
}

.slide-fade6-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1) 0.8s;
}

.slide-fade7-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1) 0.6s;
}

.slide-fade8-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1) 0.4s;
}

.slide-fade9-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1) 0.2s;
}

.slide-fade10-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1) 0s;
}

.slide-fade1-enter,
.slide-fade1-leave-to,
.slide-fade2-enter,
.slide-fade2-leave-to,
.slide-fade3-enter,
.slide-fade3-leave-to,
.slide-fade4-enter,
.slide-fade4-leave-to,
.slide-fade5-enter,
.slide-fade5-leave-to,
.slide-fade6-enter,
.slide-fade6-leave-to,
.slide-fade7-enter,
.slide-fade7-leave-to,
.slide-fade8-enter,
.slide-fade8-leave-to,
.slide-fade9-enter,
.slide-fade9-leave-to,
.slide-fade10-enter,
.slide-fade10-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

/* 滚动条美化 */
::-webkit-scrollbar {
  width: 3px;
  height: 7px;
  background-color: #f1f1f1;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  border-radius: 10px;
  background-color: #f1f1f1;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1); */
  background-color: #c8c8c8;
}


.no-permission {
  color: #c8c9cc;
  line-height: 2rem;
}
a,a:visited{
  color: #fff;
}
.tab-content{
  line-height: 2rem;
}
.container header .header-right{
  position: absolute;
  // margin-right: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  top: 0;
  span{
    font-weight: normal;
    font-size: 20px;
  }
  button{
    flex-shrink: 0;
    margin-left: 20px;
    padding: 0 16px;
    height:36px;
    line-height: 36px;
    font-size: 16px;
    border: none;
    outline: none;
    background:rgba(161, 199, 247, .4);
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    &:hover{
      opacity: 0.8;
    }
  }
}
</style>
